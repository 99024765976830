// This file is only for styles that are not specific per website - global fixes and improvements only.
// _base.scss imports _custom.scss and other partials.
@import "../base";

/* =============================================================================
    Branding (webks)
   ========================================================================== */
a[href="http://www.webks.de"]:hover{ color:#009cff !important; }
a[href="http://www.drowl.de"]:hover{ color:#82b22c !important; }

/* -- Colors */
.webks-blue{ color:#009cff; }
.drowl-green{ color:#83B32E; }

/* -- Maintenance Page */
body.maintenance-page{
  background: url("../../images/gear_bg.png") center bottom no-repeat #eaeaea !important;
  color: #000000;
  height: 100%;
  margin: 0;
  padding: 0;
}
body.maintenance-page h1,
body.maintenance-page h2{ color:#454545; }
body.maintenance-page #maintenance-branding{ padding-top:50px; text-align:center; }
body.maintenance-page #maintenance-main-content{
  background-color: rgba(255, 255, 255, 0.7);
  border-color: #009CFF;
  border-image: none;
  border-style: solid none none;
  border-width: 11px 0 0;
  box-shadow: 2px 2px 15px #7D7D7D;
  color: #6F6F6F;
  margin: 45px auto 0;
  max-width: 500px;
  min-height: 150px;
  overflow: hidden;
  padding: 15px 25px;
  text-align: right;
}
body.maintenance-page #webks-bottom {
  background: none repeat scroll 0 0 rgba(255,255,255,0.5);
  color:#6f6f6f;
  bottom: 0;
  padding: 20px 2%;
  position: absolute;
  width: 96%;
}
body.maintenance-page #webks-bottom ul.menu{ text-align:right; }
body.maintenance-page #webks-bottom .label{ margin-right:5px; }
body.maintenance-page #webks-bottom a{ color:#6f6f6f; display:inline-block; padding:0; }
body.maintenance-page #webks-bottom a:hover{ -moz-transition: all 0.1s linear; -moz-transform:translate(0.15em,0); }
body.maintenance-page #webks-contact-block { 
	float:right;
}
body.maintenance-page #webks-contact-block h2{ 
	margin:0;
	text-align:right;
	font-weight:normal;
}
body.maintenance-page #maintenance-site-name{ margin: 0 auto; text-align: right; max-width: 500px; }
body.maintenance-page #maintenance-page-title{ font-size: 1.4em; font-weight: normal; margin-bottom: 0.25em; }

/* =============================================================================
    Modules
   ========================================================================== */
/* Devel Module */
.dev-query {
  background: #eee;
  padding: 30px;
}

/* TinyMCE Skin improvements */
.defaultSkin{ 
  table.mceLayout{
    border:0 none !important;
    tr.mceFirst td{
      border-top:0 none !important;
    }
  }
}

/* Responsive Tables */
.webks-responsive-table{ margin-top:1.5em; }
.webks-responsive-table .row-0{ margin-top:2em; }
.webks-responsive-table dl{ border-bottom: 1px solid #ccc; }
.webks-responsive-table dt{ font-weight:bold; }
.webks-responsive-table dd{ margin-left:0; }

/* Module fixes */
/* -- IE6 no more */
#ie6nomore{ height:auto; padding-bottom:5px; }
#ie6nomore-title{ line-height:1.1em; }

/* =============================================================================
    Scripts CSS
   ========================================================================== */

/* Clickable Rows */
.responsive-views-row-clickable{ cursor:pointer; }

/* =============================================================================
    Dashboard
   ========================================================================== */

.page-admin-dashboard #page{
  max-width:none !important;
}

#webks-dashboard{
  // Panels
  font-family: arial, verdana, sans-serif;
  .region-two-66-33-first{
    
  }
  .panel-pane{
    margin-bottom:0.75em;
  }
  .pane-title{
    background:$highlight;
    color:$highlight_text_color;
    font-size:1em;
    padding:5px;
    margin-top:0;
    a{
      color:$highlight_text_color;
    }
  }
  // Views
  .view-filters .views-exposed-widget{
    border:0 none;
    width:auto;
    padding:0 5px;
    min-height:none;
    &.views-submit-button{
      clear:none;
    }
  }
  // Sidebar
  .region-two-66-33-second{
    .menu li{
      border-bottom:1px solid $grey;
      &:last-child{
        border-bottom:0;
      }
    }
    .node-add-menu li a{
      @include ico('plus');
      &:before{
        color:$green;
      }
    }
    .panel-pane{
      background:$grey_light;
      &:not(.pane-menu-block):not(.pane-search) .pane-content{
        padding:5px;
      }
    }
  }
  // Suche 
  .search-form{
    border-top:3px solid $highlight;
    background:$grey_light;
    padding:5px;
  }
}

/* =============================================================================
    Media Bar
   ========================================================================== */
/* NODE EDIT IMPROVEMENTS - TODO in modul auslagern (bedenken - kein scss dort), styles sollten nur eingeloggt geladen werden */
/* -- Styling insert buttons */
#page .insert-button{
  padding-left:35px;
  background:url(images/ico_insert.png) no-repeat 9px center #84bf00;
  color:#253600;
}
/* -- Trigger Button beyond body field */
.side-panel-trigger{
  margin-top:10px;
  .underline{
    display:block;
  }
}

/* -- Sidepanel (media bar) */
body.sidepanel-open{
  padding-right:33%;
}

.js .node-form .group-drowl-mediabar.open{
  width:33%;
  overflow-y:auto;
  padding:10px;
  .side-panel-closer{
    @include btn('red', 'default', 'remove');
    display:block;
    padding:5px 5px 5px 35px;
    position:relative;
    margin-bottom:5px;
  }
}
.js .node-form .group-drowl-mediabar{
  position:fixed;
  right:0;
  top:0;
  height:100%;
  width:5px;
  overflow:visible;
  background:white;
  color:$webks_grey;
  padding:0;
  z-index:5000;
  @include transition-duration(0.5s);
  .side-panel-trigger{
    display:block;
    width:80px;
    line-height:normal;
    background:white;
    position:absolute;
    left:-100px;
    top:50%;
    text-align:center;
    margin-top:-25px;
    cursor:pointer;
    padding:50px 10px 10px 10px;
    box-shadow:-5px 0px 3px rgba(0,0,0,0.1);
    font-weight:bold;
    &:before{
      background:darken($webks_blue, 10%);
      content:" ";
      position:absolute;
      left:32px;
      top:15px;
      height:20px;
      width:20px;
    }
    &:after{
      background:$webks_blue;
      content:" ";
      position:absolute;
      left:42px;
      top:25px;
      height:20px;
      width:20px;
    }
  }
  .side-panel-closer{
    display:none;
    cursor:pointer;
  }
  table{
    border:0 none;
    tr{
      border-bottom:1px solid $webks_grey_light;
      color:$webks_grey;
      td{
        vertical-align:top;
        &:last-child{
          width:30%;
          .btn{
            display:block;
          }
          .form-submit{
            @include btn-mod('red');
            display:block;
            width:100%;
            margin-bottom:4px;
          }
        }
      }
      &.drag{
        background:#fffdea;
      }
      &.drag-previous{
        -webkit-animation: dragging-complete 2s 1; /* Safari 4+ */
        -moz-animation:    dragging-complete 2s 1; /* Fx 5+ */
        -o-animation:      dragging-complete 2s 1; /* Opera 12+ */
        animation:         dragging-complete 2s 1; /* IE 10+ */
      }
    }
    thead{
      th{
        background:$webks_grey;
        color:#fff;
        a{
          color:#fff;
        }
      }
    }
  }
  table.sticky-header{ display:none !important; }
  .image-widget{
    @include clearfix-after;
  }
  .image-preview{
    width: 60px;
    background:white;
  }
  .field-name-field-titelbild .image-preview{
    width:auto;
  }
  .image-widget-data{
    float:none;
  }
  legend{
    background:transparent;
    color:white;
    font-weight:bold;
  }
  fieldset{
    background:transparent;
    padding:0;
    margin:0 0 5px 0;
    border:0 none;
    box-shadow:none;
  }
  .fieldset-wrapper{
    padding:0;
  }
  .fieldset-description{
    background:#efefef;
    border:1px solid white;
    padding:5px;
    color:black;
    font-style:italic;
    a{
      color:black;
    }
  }
  .form-text{
    width:100%;
  }
  .horizontal-tabs{
    border:0 none;
    .horizontal-tabs-list{
      background:white;
      li{
        background:white;
        width:50%;
        border:0 none;
        padding:0;
        a{
          color:#454545;
          padding:7px 10px;
          border-bottom:1px solid #ccc;
          &:hover{
            background:white;
          }
          &:before{
            content:" ";
            float:left;
            display:inline-block;
            width:31px;
            height:31px;
            background:url(../images/media_sprite.png) no-repeat 0 0 white;
            border:1px solid #e7e7e7;
            margin-right:5px;
          }
        }
        &:hover a:before,
        &.selected a:before{
          border-color:#a0e34b;
        }
        &:nth-child(odd) a{
          border-right:1px solid #ccc;
        }
        &.selected strong{
          font-weight:bold;
        }
        // Add Icons
        &.horizontal-tab-button-0 a:before{
          //Title image
          background-position:-64px 0;
        }
        &.horizontal-tab-button-1 a:before{
          //Bilder (inhalt)
          background-position:-97px 0;
        }
        &.horizontal-tab-button-2 a:before{
          //Galerie
          background-position:-31px 0;
        }
        &.horizontal-tab-button-3 a:before{
          //Anhang
          background-position:-130px 0;
        }
      }
      strong{
        font-weight:normal;
        line-height:30px;
        vertical-align:middle;
      }
    }
  }
  .field-name-field-attachments{
    // Undo .box-style
    background:none;
    padding:0;
    margin:0;
    border:0 none;
  }
  .form-item{
    background:white;
    padding:10px;
    color:black;
    border-width:0 0 1px 0;
    border-style:none none solid none;
    border-color:$webks_grey_light;
    border-radius:0;
  }
  .image-widget-data{
    clear:both;
  }
  .sticky-table .form-item,
  .image-widget-data .form-item{
    box-shadow:none;
  }
  .insert{
    clear:both;
    @include clearfix-after;
    border:1px solid #eee;
    border-radius:3px;
    .insert-style-select{
      width:130px;
      float:right;
      label{
        display:none;
      }
    }
    .form-submit{
      float:left;
      @include btn-mod('green');
    }
  }
}

/* Media Bar: headroom.js support */
.js .headroom-bar-pinned .node-form .group-drowl-mediabar{
  top:50px; // height of the docked navigation bar
}